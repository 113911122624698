import React from "react";
import styles from "./libertyStreams.module.css"

const LibertyStreams = () => (
    <div className={styles.p}>
        <div className="d-flex justify-content-center align-items-center vh-100">
            <p className="text-large">More on this soon!</p>
        </div>
    </div>
);

export default LibertyStreams;
